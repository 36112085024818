<template>
    <div id="main-container" class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell--span-12">
          <br>
        <LoadingForum v-if="isLoading" ref="loader" @loaded="finishLoading" :message="loadingMessage" />
        <div class="iframe-container" :class="{ 'loading-hidden': isLoading, 'loading-visible': !isLoading }">
            <iframe name="InfoBolsa" id="info-bolsa-id" style="overflow:hidden" width="100%" height="98%" frameBorder="0" @load="handleIframeLoad"></iframe>
        </div>
      </div>
    </div>
        <!--<div id="store">
    <p>TIENDA</p>
    <div>
        <Accordion class="widgets">
            <AccordionItem>
                <template slot="accordion-trigger">
                    <div>
                        <ol>
                            <li class="sector-text text-bold">Widgets</li>
                        </ol>
                    </div>
                    <div>
                        <span class="material-icons accordion-icon">keyboard_arrow_down</span>
                    </div>
                </template>
                <template slot="accordion-content">
                    <div class="accordioin-content-align">
                        <div style="display:flex; flex-wrap: wrap;">
                            <div v-for="(item, index) in widgets" :key="index" >
                                <div class="carta-box">
                                    <div class="carta">    
                                        <div class="cara">
                                            <div style="width:250px; height: 300px;">
                                                <img src="https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/herramientas/main/sectores_rentables.jpg" alt="Mi titulo de la imagen" style="width:250px; height: 300px;border:1px solid transparent; border-radius: 10px">                                               
                                            </div>
                                            <div>
                                                <p class="title">{{item.title}}</p>
                                                <p class="price">Precio: {{item.price}}</p>
                                            </div>
                                        </div>
                                        <div class="cara detras">
                                            <div style="width:250px; height: 300px;">
                                                <img src="https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/herramientas/main/sectores_rentables.jpg" alt="Mi titulo de la imagen" style="width:250px; height: 300px;border:1px solid transparent; border-radius: 10px">
                                                
                                            </div>
                                            <div>
                                                <p class="description">{{item.description}}</p>
                                                <p class="price">Precio: {{item.price}}</p>
                                            </div>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </AccordionItem>
        </Accordion>
    </div>
    <div>
        <Accordion class="tools">
            <AccordionItem>
                <template slot="accordion-trigger">
                    <div>
                        <ol>
                            <li class="sector-text text-bold">Herramientas</li>
                        </ol>
                    </div>
                    <div>
                        <span class="material-icons accordion-icon">keyboard_arrow_down</span>
                    </div>
                </template>
                <template slot="accordion-content">
                    <div class="accordioin-content-align">
                        <div style="display:flex; flex-wrap: wrap;">
                            <div v-for="(item, index) in widgets" :key="index">
                                <div class="carta-box">
                                    <div class="carta">    
                                        <div class="cara">
                                            <div style="width:250px; height: 300px;">
                                                <img src="https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/herramientas/main/sectores_rentables.jpg" alt="Mi titulo de la imagen" style="width:250px; height: 300px;border:1px solid transparent; border-radius: 10px">                                                
                                            </div>
                                            <div>
                                                <p class="title">{{item.title}}</p>
                                                <p class="price">Precio: {{item.price}}</p>
                                            </div>
                                        </div>
                                        <div class="cara detras">
                                            <div style="width:250px; height: 300px;">
                                                <img src="https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/herramientas/main/sectores_rentables.jpg" alt="Mi titulo de la imagen" style="width:250px; height: 300px;border:1px solid transparent; border-radius: 10px">                                                
                                            </div>
                                            <div>
                                                <p class="description">{{item.description}}</p>
                                                <p class="price">Precio: {{item.price}}</p>
                                            </div>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </AccordionItem>
        </Accordion>
    </div>
    <div>
        <Accordion class="funcionalidades">
            <AccordionItem>
                <template slot="accordion-trigger">
                    <div>
                        <ol>
                            <li class="sector-text text-bold">Funcionalidades</li>
                        </ol>
                    </div>
                    <div>
                        <span class="material-icons accordion-icon">keyboard_arrow_down</span>
                    </div>
                </template>
                <template slot="accordion-content">
                    <div class="accordioin-content-align">
                        <div style="display:flex; flex-wrap: wrap;">
                            <div v-for="(item, index) in widgets" :key="index" >
                                <div class="carta-box">
                                    <div class="carta">    
                                        <div class="cara">
                                            <div style="width:250px; height: 300px;">
                                                <img src="https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/herramientas/main/sectores_rentables.jpg" alt="Mi titulo de la imagen" style="width:250px; height: 300px;border:1px solid transparent; border-radius: 10px">                                                
                                            </div>
                                            <div>
                                                <p class="title">{{item.title}}</p>
                                                <p class="price">Precio: {{item.price}}</p>
                                            </div>
                                        </div>
                                        <div class="cara detras">
                                            <div style="width:250px; height: 300px;">
                                                <img src="https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/herramientas/main/sectores_rentables.jpg" alt="Mi titulo de la imagen" style="width:250px; height: 300px;border:1px solid transparent; border-radius: 10px">                                                
                                            </div>
                                            <div>
                                                <p class="description">{{item.description}}</p>
                                                <p class="price">Precio: {{item.price}}</p>
                                            </div>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </AccordionItem>
        </Accordion>
    </div>
</div>-->
</template>

<script>
import { mapGetters } from "vuex";
import LoadingForum from '@/components/stockchart/components/LoadingForum.vue';

export default {
  name: 'InfoBolsa',
  components: {
    LoadingForum
  },
  data: () => ({
    isLoading: true,
    loadingMessage: "Cargando información de bolsa..."
  }),
  mounted() {
    this._setIframe();
  },
  created: function() {
  },
  computed: {
    ...mapGetters(["_g_IsUserLogged"]),
  },
  methods: {
    finishLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    
    handleIframeLoad() {
      const iframe = document.getElementById("info-bolsa-id");
      
      if (iframe && iframe.src && iframe.src.trim() !== '') {
        if (this.$refs.loader) {
          this.$refs.loader.finishLoading();
        }
      }
    },
    
    _setIframe(){
        var pricingFrame = document.getElementById("info-bolsa-id");
        pricingFrame.src = process.env.VUE_APP_API + '/informacion-bolsa'

    },

    _goToInforme: function(){
        window.location.replace(process.env.VUE_APP_API + "/informacion-bolsa");
    }
  },
}

</script>

<style lang="scss" scoped>

  #main-container{
    height:100%;
    width:100%;
    overflow:hidden !important;
  }

  .iframe-container{
    height:100% !important;
  }
  
  header{
    display:none !important;
  }

  /* Initially hide the main content but keep it rendered */
  .iframe-container {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  /* When loading finishes, fade it in */
  .loading-visible {
    opacity: 1;
  }

  /* Keep it hidden during loading */
  .loading-hidden {
    opacity: 0;
  }

    //#store{
    //  width: 100%;
    //  height: 100%;
    //  margin: 0 0 0 15px;
    //}
    //.widgets{
    //    width: 100%;
    //    height: 30%;
    //}
    //.tools{
    //    width: 100%;
    //   height: 30%;
    //}
    //.funcionalidades{
    //    width: 100%;
    //    height:30%;
    //}

    //.carta-box {
    //    margin: 20px;
    //    width: 250px;
    //    height: 300px;
    //    position: relative;
    //    perspective: 1000px;
    //}

    //.carta-box:hover .carta {
    //    transform: rotateY(180deg);
    //}

    //.carta {
    //    transform-style: preserve-3d;
    //    transition: all 0.5s linear;
    //}

    //.cara {
    //    position: absolute;
    //    backface-visibility: hidden;
    //}

    //.cara.detras {
    //    transform: rotateY(180deg);
   //}
    //.price {
    //    background-color: black;
    //    position: absolute;
    //    color: white;
    //   top: 200px;
    //    left: 1px;
    //    width: 100%;
    //    font-weight: bold;
    //    font-size: 20px;
    //}
    //.title {
    //    background-color: black;
    //    position: absolute;
    //   color: white;
    //    top: 0px;
    //    left: 1px;
    //    width: 100%;
    //   font-weight: bold;
    //    font-size: 24px;
    //}
    //.description {
    //    text-align:center;
    //    background-color: #484b54;
    //    position: absolute;
    //    color: white;
    //    top: 0px;
    //    left: 1px;
    //    width: 100%;
    //    font-size: 16px;
    //}
  
</style>